import {useEffect, useState} from "react";

export default function ContentCountDown(doc: any) {
    const countDownDate = new Date(doc.date).getTime();
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const ct = countDownDate - new Date().getTime();
            const values: any = getReturnValues(ct);
            setDays(values[0] > 0 ? values[0] : 0);
            setHours(values[1] > 0 ? values[1] : 0);
            setMinutes(values[2] > 0 ? values[2] : 0);
            setSeconds(values[3] > 0 ? values[3] : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    const getReturnValues = (countDown: any) => {
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return [days, hours, minutes, seconds];
    };

    return (
        <div className={"border-light rounded-3 bg-dark p-5 mt-3 mb-4 text-center"} datatype={"content-count-down"}>
            {(days + hours + minutes + seconds <= 0) && (
                <div className={"ps-5 pe-5 pt-4 pb-4 bg-white rounded-4 fw-bold mb-4 text-center"}>
                    <i className="fa-solid fa-hourglass-end fs-4 me-3"></i>
                    <span className={"fs-5"}>Yuuhu, das Warten hat ein Ende genommen! </span>
                </div>
            )}

            <div className={"d-flex flex-row m-auto justify-content-center countdown"}>
                <div className={"me-1 me-md-3"}>
                    <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>Tage</div>
                    <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{days}</div>
                </div>

                <div className={"me-1 me-md-3"}>
                    <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>Std.</div>
                    <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{hours}</div>
                </div>

                <div className={"me-1 me-md-3"}>
                    <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>Min.</div>
                    <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{minutes}</div>
                </div>

                <div className={"me-1 me-md-3"}>
                    <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>Sek.</div>
                    <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{seconds}</div>
                </div>
            </div>
        </div>
    )
}