export default function ContentVideo(doc: any) {
    return (
        <video controls
               preload={""}
               poster={""}
               autoPlay
               loop
               muted
               style={{maxHeight: "400px", aspectRatio: "16/9"}}
               datatype={"content-video"}
               className={"w-100 h-auto mb-4"}>
            <source key={doc.id} src={doc.videoUrl} type={"video/mp4"}/>
        </video>
    )
}