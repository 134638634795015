import Image from "next/image";
import {useRouter} from "next/router";
import {useState} from "react";
import i18next from "i18next";

export default function ContentPortfolioFilter(doc: any) {
    const router = useRouter();
    const [selectedFilter, setSelectedFilter]: any = useState();
    const [list, setList] = useState(doc.listItems);

    function linkHandler(link?: string) {
        if (!link || !link.length) return;
        return router.push(link);
    }

    function filter(tag?: string) {
        setSelectedFilter(tag);
        if (!tag) {
            setList(doc.listItems);
            return;
        }
        const l = doc.listItems.filter((item: any) => item.tags.includes(tag));
        setList(l);
    }

    return (
        <>
            <div className={"d-flex flex-row flex-wrap gap-2 mt-4 mt-md-5"}>
                <div
                    className={`border-light rounded-5 p-2 ps-3 pe-3 flex-lg-fill text-center ${!selectedFilter && 'bg-black text-white'}`}
                    onClick={() => filter()}
                    role={"button"}>
                    {i18next.t('general.showAll').toString()}
                </div>
                {doc.tags.map((tag: string) => (
                    <div key={tag}
                        className={`border-light rounded-5 p-2 ps-3 pe-3 flex-lg-fill text-center ${selectedFilter === tag && 'bg-black text-white'}`}
                        onClick={() => filter(tag)}
                        role={"button"}>
                        {tag}
                    </div>
                ))}
            </div>
            <div className={"row mt-4"}>
                {list.map((listItem: any) => (
                    <div key={listItem.id} className={"col-6 col-md-4 col-lg-3 mb-4"}>
                        <Image src={listItem.image.imageUrl}
                               width={400}
                               height={400}
                               alt={listItem.image.imageAlt}
                               onClick={() => linkHandler(listItem.image.imageLink)}
                               role={"button"}
                               className={"w-100 h-auto"}/>

                        <div className={"mt-4"} role={"button"} onClick={() => linkHandler(listItem.buttonLink)}>
                            <p className={"fw-bold position-relative"}>
                                <span>{listItem.titleText}</span>
                                <i className="fa-solid fa-circle-chevron-right position-absolute top-0 end-0 mt-1"></i>
                            </p>
                            <div dangerouslySetInnerHTML={{__html: listItem.text}}/>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}