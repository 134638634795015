export default function ContentText(doc: any) {

    const text = doc.text.replaceAll("_blank", " ");

    return (
        <div datatype={"content-text"}
             className={`h-100 d-block rounded-5 dark-theme-text ${doc.backgroundColor ? 'has-bg-color p-3 pt-2 pb-2' : ''}`}
             style={{backgroundColor: doc.backgroundColor ?? ''}}
             dangerouslySetInnerHTML={{__html: text}}/>
    )
}