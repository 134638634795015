import React, {useEffect, useState} from "react";
import {locale} from "../../utils/locale";
import i18next from "i18next";
import variantService from "../../services/product/variantService";
import apiInternal from "../../utils/apiInternal";
import ProductSlider from "../slider/Product";
import cookieService from "../../services/cookie/cookieService";
import {ProductTile} from "../product/Tile";

export default function ContentProductCarousel(doc: any) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fn = async () => {
            const res: any = await apiInternal().post('/api/v2/product/ids', {
                ids: doc.articles.map((a: any) => a.articleID),
                locale: locale(i18next.language),
                customerGroup: cookieService.getCustomerGroup()
            });

            await variantService.getVariants(res.data, true, cookieService.getCustomerGroup(), i18next.language);
            setProducts(res.data);
        };

        fn().then();
    }, [])

    if (!products.length) return <></>

    if (products.length < 2) {
        return (
            <div datatype={'content-product-carousel'} data-per-page={doc.perPage} className={"content-product-carousel"}>
                <ProductTile product={products[0]}/>
            </div>
        )
    }

    return (
        <div datatype={'content-product-carousel'} data-per-page={doc.perPage} className={"content-product-carousel"}>
            <ProductSlider products={products} md={1} lg={3} xl={3} mt={'mt-0'} pt={'pt-0'} perPage={doc.perPage} container={false} informationOnly={false}/>
        </div>
    )
}