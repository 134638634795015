import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import React from "react";
import single from "../../utils/single";
import Image from "next/image";
import Link from "../link/Link";
import {imageSizes} from "../../utils/imageSizes";

export default function ContentBubbleSlider(doc: any) {

    function Bubble({ block }: { block: any}) {
        return <Link href={single(block.imageLink)} className="d-block highlight-bubble text-center">
            <Image
                className="w-100 h-auto"
                src={single(block.imageUrl)}
                alt={single(block.imageAlt)}
                width={180}
                height={180}
                sizes={imageSizes({
                    xs: '140px',
                    xl: '160px',
                    xxl: '180px',
                })} />
            <div className="mt-2 fw-bold">{single(block.text)}</div>
        </Link>;
    }
    

    return (
        <Swiper
            slidesPerView="auto"
            mousewheel={{forceToAxis: true}}
            freeMode
            modules={[Mousewheel, FreeMode]}
            wrapperClass="d-flex justify-content-between"
        >
            {doc.listItems.map((block: any, index: number) => {
                let className = 'w-auto';
                if (index > 0) {
                    className += ' ms-1';
                }
                if (index < doc.listItems.length - 1) {
                    className += ' me-1';
                }
                return (
                    <SwiperSlide className={className}>
                        <Bubble block={block} key={index} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    )
}