import moment from "moment-timezone";
import Link from "../link/Link";
import React, {useState} from "react";

export default function ContentImageGrid(doc: any) {

    let theme: string = 'image-grid-theme-light';
    if(doc.isDarkTheme) {
        theme = 'image-grid-theme-dark';
    }

    const [openCardId, setOpenCardId] = useState(null);

    function openCard(id: any) {
        setOpenCardId(id);
    }
    function closeCard() {
        setOpenCardId(null);
    }

    const now = moment().tz('Europe/Zurich').format('YYYY-MM-DD HH:mm:ss');

    function isItemActive(item: any) {
        let active = '';
        const start = moment(item.start, 'YYYY-MM-DD HH:mm:ss').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss');
        const end = moment(item.end, 'YYYY-MM-DD HH:mm:ss').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss');
        let ended = false;
        if(item.end !== null && now > end) {
            ended = true;
        }
        if (item.start !== null) {
            if (now > start && !ended) {
                active = 'active';
            }
        } else {
            if(!ended) {
                active = 'active';
            }
        }
        return active;
    }

    function showCard(item: any, showX: boolean, showLink: boolean){
        if (doc.effectType == 'border') {
            return showCardBorder(item, showX, showLink);
        } else {
            // none || flip
            return showCardFlip(item, showX, showLink);
        }

    }

    function showCardFlip(item: any, showX: boolean, showLink: boolean){
        return (
            <div className="grid-card">

                {showLink && item.link !== null ? (
                    <Link href={item.link} className={"full-link"}></Link>
                ): (<></>)}

                <div className="grid-card-front">
                    {item.displayNumber ? (
                        <div className="list-item-number">
                            {item.numberToDisplay}
                        </div>
                    ): (<></>)}
                </div>


                <div className="grid-card-back">
                    {showX ? (
                        <i className="fa-solid fa-close close-image-grid-card" onClick={() => closeCard()}></i>
                    ): (<></>)}

                    <div className="list-item-text">
                        {item.text}

                        {item.text !== null ? (
                            <br/>
                        ) : (<></>)}

                        {item.cta ? (item.cta) : (doc.defaultCTA)}
                    </div>
                    <div className="list-item-text-inactive">
                        {doc.inactiveText}
                    </div>
                </div>

            </div>
        )
    }

    function showCardBorder(item: any, showX: boolean, showLink: boolean){
        return (

            <div className={`grid-2-card ${openCardId == item.id ? 'active' : ''}`}>
                {showX ? (
                    <i className="fa-solid fa-close close-image-grid-card" onClick={() => closeCard()}></i>
                ): (<></>)}

                {showLink && item.link !== null ? (
                    <Link href={item.link} className={"full-link"}></Link>
                ): (<></>)}

                {item.displayNumber ? (
                    <div className="list-item-number">
                        {item.numberToDisplay}
                    </div>
                ): (<></>)}

                <div className="grid-2-card__text">
                    {showLink? (
                        <>
                            <div className="list-item-text">
                                <p className="grid-2-card__body">
                                    {item.text}
                                    {item.link !== null ? (
                                        <>
                                            <br/>
                                            <Link href={item.link}
                                                  role={"button"}
                                                  className={`btn btn-image-grid text-white fw-bold ${item.text ? 'mt-1' : 'mt-3'} ps-1 pe-1 pt-0 pb-0 w-auto d-inline-blockme-4`}>
                                                <span>{item.cta ? (item.cta) : (doc.defaultCTA)}</span>
                                                <i className="fa-solid fa-chevron-right ms-2 mt-1 text-white"></i>
                                            </Link>
                                        </>
                                    ): (<></>)}
                                </p>
                            </div>
                            <div className="list-item-text-inactive">
                                <p className="grid-2-card__body">
                                    {doc.inactiveText}
                                </p>
                            </div>
                        </>
                    ): (<></>)}
                </div>

            </div>

        )
    }

    return (
        <div datatype={`category-content-image-grid `}>

            <div className={`image-grid ${theme}`}>
                <img src={doc.imageUrl} style={{width: '100%'}} />

                <div className={`article-list 
                    columns-count-x-${doc.columnsCountMobile} 
                    columns-count-x-sm-${doc.columnsCountTablet} 
                    columns-count-x-md-${doc.columnsCountDesktop}`}>

                    {doc.listItems.map((item: any) => (
                        <div key={'al-'+item.id} className="article-list-item" style={{gridColumn: 'span '+ item.numberColumns, gridRow: 'span '+ item.numberRows }}>
                        </div>
                    ))}
                </div>

                <div className={`article-list-with-content 
                    columns-count-x-${doc.columnsCountMobile} 
                    columns-count-x-sm-${doc.columnsCountTablet} 
                    columns-count-x-md-${doc.columnsCountDesktop}`}>

                    {doc.listItems.map((item: any) => (
                        <>
                            <div key={'alc-'+item.id} className={`d-none d-md-block article-list-item ${isItemActive(item)}`}
                                 style={{gridColumn: 'span '+ item.numberColumns, gridRow: 'span '+ item.numberRows }}
                            >
                                {showCard(item, false, true)}
                            </div>
                            <div key={'alc-'+item.id} className={`d-block d-md-none article-list-item ${isItemActive(item)}`}
                                 style={{gridColumn: 'span '+ item.numberColumns, gridRow: 'span '+ item.numberRows }}
                                 onClick={() => openCard(item.id)}
                            >
                                {showCard(item, false, false)}
                            </div>
                        </>
                    ))}

                </div>


                <div className={`article-list-mobile-content ${openCardId !== null ? 'active' : ''}`}>

                    {doc.listItems.map((item: any) => (
                        <div key={'alcm-'+item.id} className={`article-list-item article-list-item-mob-pop-up ${openCardId == item.id ? 'show' : ''} ${isItemActive(item)}`}>
                            {showCard(item, true, true)}
                        </div>
                    ))}

                </div>

            </div>
        </div>
    )
}