import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import React from "react";
import contentRenderer from "../../services/content/contentRenderer";

export default function ContentImageCarousel(doc: any) {
    const breakpoints: any = {
        1: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 1
        },
        992: {
            slidesPerView: 1
        },
        1200: {
            slidesPerView: 1
        },
    }

    return (
        <>
            <Swiper pagination={{clickable: true}}
                    breakpoints={breakpoints}
                    mousewheel={{forceToAxis: true}}
                    freeMode={{sticky: true}}
                    modules={[Pagination, Mousewheel, FreeMode, Navigation]}
                    navigation={true}
                    className="product-slider"
                    preventClicks={true}
                    datatype={"category-content-image-carousel"}
                    loop={false}>
                {doc.listItems.map((item: any) => (
                    <SwiperSlide key={item.id}>
                        {contentRenderer.renderComponent(item.type, item)}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}