import React, {useEffect, useState} from "react";
import {locale} from "../../utils/locale";
import i18next from "i18next";
import apiInternal from "../../utils/apiInternal";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import contentRenderer from "../../services/content/contentRenderer";
import Image from "next/image";
import DailyDealTile from "../daily-deal/DailyDealTile";
import productLinkService from "../../services/product/productLinkService";
import imageService from "../../services/product/imageService";
import Link from "../link/Link";

import PastDailyDealTile from "../daily-deal/PastDailyDealTile";
import { useRouter } from 'next/router';
import moment from "moment-timezone";


export default function ContentEventCalendar(doc: any) {
    
    const [dailyDeals, setDailyDeals] = useState<any>();
    const [todayItem, setTodayItem] = useState<any>();
    const [activeItems, setActiveItems] = useState<any>([]);
    const [pastItems, setPastItems] = useState<any>([]);

    const breakpoints: any = {
        1: {
            slidesPerView: 1
        },
        340: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 1
        },
        992: {
            slidesPerView: 1
        },
        1200: {
            slidesPerView: 1
        },
    };

    useEffect(() => {
        const fn = async () => {

            const todayDate = Date.parse(moment().tz('Europe/Zurich').format('YYYY-MM-DD'));

            const sortedItems = doc.listItems.sort((a: any, b: any) => {
                return Date.parse(b.start) - Date.parse(a.start);
              });

            const res: any = await apiInternal().post('/api/v2/product/deal', {
                languageId: locale(i18next.language),
                count: 0
            });
            const data = res.data;

            await setDailyDeals(data);
            
            const todayItems = sortedItems.filter((item: any) => {
                return Date.parse(item.start) <= todayDate && Date.parse(item.end) >= todayDate;
            } );

            if(todayItems.length > 0) {
                if(todayItems[0].isDailyDeal) {
                    const deal = res.data.dailyDeals.filter((deal: any) => {
                        return deal.dailyDealId == todayItems[0].dailyDealId;
                    });
                    if(deal) {
                        todayItems[0].deal = deal[0];
                    }
                } 
                setTodayItem(todayItems[0]);
            }

            let pastAndActivDeals: any[] = [];
            let pastInactiveItems: any[] = [];
            
            sortedItems.map((item: any) => {
                if( Date.parse(item.start) < todayDate && Date.parse(item.end) < todayDate) {
                    if(item.isDailyDeal) {
                        const deal = res.data.dailyDeals.filter((deal: any) => {
                            return deal.dailyDealId == item.dailyDealId;
                        });
                        if(deal){
                            item.deal = deal[0];
                        }
                        if (item.deal && item.deal.current_amount > 0) {
                            pastAndActivDeals.push(item);
                        } else {
                            pastInactiveItems.push(item);
                        }
                    } else {
                        pastInactiveItems.push(item);
                    }
                }
            } );

            if(pastAndActivDeals.length > 0) {
                setActiveItems(pastAndActivDeals);
            }

            if (pastInactiveItems.length > 0) {
                setPastItems(pastInactiveItems);
            }

        };

        fn().then();

    }, []);

    function getColorStyle(item:any, isPast: boolean = false){
        if(isPast) {
            return {};
        }
        if (item.backgroundColor && item.backgroundColorText) {
            return {backgroundColor: item.backgroundColor, color: item.backgroundColorText};
        } else if(item.backgroundColor) {
            return {backgroundColor: item.backgroundColor};
        } 
        if(item.backgroundColorText) {
            return {color: item.backgroundColorText};
        } 
        
        return {};
    }

    function getAlignment(item:any) {
        let alignment = '';
        if(item.alignmentsText == 'center') {
            alignment = 'align-items-center';
        } else if(item.alignmentsText == 'right') {
            alignment = 'align-items-end';
        } else {
            alignment = 'align-items-start';
        }
        return alignment;
    }
    function getTextAlignment(item:any) {
        let alignment = {};
        if(item.alignmentsText == 'center') {
            alignment = {textAlgin: 'center'};
        } else if(item.alignmentsText == 'right') {
            alignment = {textAlgin: 'end'};
        } else {
            alignment = {textAlgin: 'start'};
        }
        return alignment;
    }

    
    function showToday() {
        if (!todayItem)
            return <></>;

        return (
            <>
                <div className={"col-12 col-auto"}>
                    <h2>{doc.todayTitle}</h2>
                    <p>{doc.todayText}</p>
                </div>
                <div className="unievent-container">
                    <div className="unievent-card">
                        <div className="unievent-img-container">
                            <img className="unievent-mobile" src={todayItem.mobileImage.imageUrl} alt=""/>
                            <img className="unievent-desktop" src={todayItem.desktopImage.imageUrl} alt=""/>
                        </div>
                        <div className="unievent-card-details d-flex justify-content-center" style={getColorStyle(todayItem)}>
                            {showTile(todayItem)}
                        </div>
                    </div>
                </div>

            </>
        )

    }
    
    function showPast() {
        if (pastItems.length == 0)
            return <></>;

        return (
            <>
                <div className={"col-12 col-auto"}>
                    <h2>{doc.pastTitle}</h2>
                    <p>{doc.pastText}</p>
                </div>
                <div className="row">
                    <div className="col-12 eventcalendar-past">
                    <Swiper pagination={{clickable: true}}
                            breakpoints={breakpoints}
                            mousewheel={{forceToAxis: true}}
                            freeMode={{sticky: true}}
                            modules={[Pagination, Mousewheel, FreeMode, Navigation]}
                            navigation={true}
                            className="unicalendar-slider"
                            preventClicks={true}
                            datatype={"category-content-image-carousel"}
                            loop={false}>
                                
                        {pastItems.map((item: any) => (
                            <SwiperSlide key={item.id}>
                                {showTile(item, true)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                        
                    </div>
                </div>

            </>
        )

    }

    function showStillActive(){
        
        if (activeItems.length == 0)
            return <></>;

        return (
            <>
                <div className={"col-12 col-auto"}>
                    <h2>{doc.stillActiveTitle}</h2>
                    <p>{doc.stillActiveText}</p>
                </div>
                <div className="row">
                    <div className="col-12">
                    <Swiper pagination={{clickable: true}}
                            breakpoints={breakpoints}
                            mousewheel={{forceToAxis: true}}
                            freeMode={{sticky: true}}
                            modules={[Pagination, Mousewheel, FreeMode, Navigation]}
                            navigation={true}
                            className="unicalendar-slider"
                            preventClicks={true}
                            datatype={"category-content-image-carousel"}
                            loop={false}>
                        {activeItems.map((item: any) => (
                            <SwiperSlide key={item.id}>
                                {showTile(item)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                        
                    </div>
                </div>

            </>
        )
    }

    function showTile (item: any, isPast = false){
        if(item.isDailyDeal) {
            if (isPast) 
                return showDealPastTile(item);
            else
                return showDealTile(item);
        } else {
            return showContentTile(item, isPast);
        }
    }

    function showContentTile (item: any, isPast = false){
        return (
            <>
            
                <div id={item.id} className={`pb-4 d-flex flex-column ${getAlignment(item)}`} style={getColorStyle(item, isPast)} datatype={"content-eventcalender-item"}>
                    
                    <h3>{item.titel}</h3>
                    {(item.contentImage.imageUrl ) ? (
                        <img className="my-3 max-width-80" src={item.contentImage.imageUrl} alt={item.contentImage.imageAlt}/>
                    ) : (<></>)}

                    <p  className="eventcalendar-p" style={getTextAlignment(item)}
                        dangerouslySetInnerHTML={{__html: item.text}} >
    
                    </p>


                    {(item.link ) ? (
                        <Link href={item.link}
                            role={"button"}
                            className={`btn btn-black text-white fw-bold ps-3 pe-3 pt-2 pb-2 w-auto d-inline-blockme-4`}>
                            <span>{item.ctaText}</span>
                            <i className="fa-solid fa-chevron-right ms-2 mt-1 text-white"></i>
                        </Link>
                    ) : (<></>)}
                </div>

            </>
        );
    }

    function showDealTile (item: any){
        if(!item.deal) {
            return <></>;
        }

        return (
            <div className="p-3 d-flex justify-content-center">
                <DailyDealTile
                    key={item.deal.id}
                    product={item.deal}
                    productTile={false}
                    className={"w-100 ms-auto row me-auto p-3"}
                    showDescription={false}
                    style={{maxWidth: '800px'}}
                    showWide={true}
                    showCart={true}
                />
            </div>
        )
    }

    
    function getCover(product: any, mainVariant: any) {
        if (product.cover && product.cover.includes('cdn.perfecthair.ch')) return product.cover;
        return mainVariant.imgDetailUrls ? imageService.getImage(mainVariant.imgDetailUrls, product.orderNumber) : imageService.getImage(mainVariant.imgMainUrls, product.orderNumber)
    }


    function showDealPastTile (item: any){
        if(!item.deal) {
            return <></>;
        }

        return (
            <div className="d-flex flex-column align-items-center justify-content-center p-3">
                    <Image src={getCover(item.deal, item.deal)}
                        alt={item.deal.name}
                        width={180}
                        height={180}
                        sizes="(max-width: 767px) 15vw, 10vw"
                        className="mb-3 mt-3"/>
                    <div className="product-tile-text product-tile-title mb-2"><b>{item.deal.brand}</b></div>
                    <div className="product-tile-text multi-line mb-2">{item.deal.name}</div>
            </div>
        )
    }
    


    return (
        <>
            <div className={'row'} datatype={"content-event-calendar"}>

                {showToday()}

                {showStillActive()} 

                {showPast()} 

            </div>
        </>
    )
}