import Script from "next/script";

export default function ContentHtml(doc: any) {
    const scriptSrc = getScriptSrc();

    function getScriptSrc() {
        try {
            const scriptTag = doc.code.includes('<script');
            if (!scriptTag) return false;
            const start = doc.code.indexOf('<script');
            const end = doc.code.indexOf('</script>');

            const script = doc.code.substring(start, end + 9);
            const startSrc = script.indexOf('src=');
            const endSrc = script.substring(startSrc + 4).indexOf('"');
            return script.substring(startSrc + 4).substring(startSrc, endSrc + 1);
        }
        catch(e) {
            return false;
        }
    }

    return (
        <>
            {scriptSrc ? (
                <Script id={``} src={scriptSrc} strategy={"afterInteractive"}/>
            ) : <></>}
            <div datatype={"category-content-html"} dangerouslySetInnerHTML={{__html: doc.code}}/>
        </>
    )
}