import {useEffect} from "react";

export default function ContentImageComparison(doc: any) {

    useEffect(() => {
        window.addEventListener('load', function () {
            initComparisons();
        });
    }, []);

    function initComparisons() {
        var x, i;
        /* Find all elements with an "overlay" class: */
        x = document.getElementsByClassName("img-comp-overlay");
        for (i = 0; i < x.length; i++) {
            /* Once for each "overlay" element:
            pass the "overlay" element as a parameter when executing the compareImages function: */
            compareImages(x[i]);
        }
    }

    function compareImages(img: any) {
        var slider: any, clicked = 0, w: any, h;
        /* Get the width and height of the img element */
        w = img.offsetWidth;
        h = img.offsetHeight;
        /* Set the width of the img element to 50%: */
        img.style.width = (w / 2) + "px";
        let imgTag = img.getElementsByTagName('img')[0];
        imgTag.style.width = (w - 0) + "px";
        /* Create slider: */
        slider = document.createElement("DIV");
        slider.setAttribute("class", "img-comp-slider");
        /* Insert slider */
        img.parentElement.insertBefore(slider, img);
        /* Position the slider in the middle: */
        slider.style.top = (h / 2) - (slider.offsetHeight / 2) + "px";
        slider.style.left = (w / 2) - (slider.offsetWidth / 2) + "px";
        /* Execute a function when the mouse button is pressed: */
        slider.addEventListener("mousedown", slideReady);
        /* And another function when the mouse button is released: */
        window.addEventListener("mouseup", slideFinish);
        /* Or touched (for touch screens: */
        slider.addEventListener("touchstart", slideReady);
        /* And released (for touch screens: */
        window.addEventListener("touchend", slideFinish);
        function slideReady(e: any) {
            /* Prevent any other actions that may occur when moving over the image: */
            e.preventDefault();
            /* The slider is now clicked and ready to move: */
            clicked = 1;
            /* Execute a function when the slider is moved: */
            window.addEventListener("mousemove", slideMove);
            window.addEventListener("touchmove", slideMove);
        }
        function slideFinish() {
            /* The slider is no longer clicked: */
            clicked = 0;
        }
        function slideMove(e: any) {
            var pos;
            /* If the slider is no longer clicked, exit this function: */
            if (clicked == 0) return false;
            /* Get the cursor's x position: */
            pos = getCursorPos(e)
            /* Prevent the slider from being positioned outside the image: */
            if (pos < 0) pos = 0;
            if (pos > w) pos = w;
            /* Execute a function that will resize the overlay image according to the cursor: */
            slide(pos);
        }
        function getCursorPos(e: any) {
            var a, x = 0;
            e = (e.changedTouches) ? e.changedTouches[0] : e;
            /* Get the x positions of the image: */
            a = img.getBoundingClientRect();
            /* Calculate the cursor's x coordinate, relative to the image: */
            x = e.pageX - a.left;
            /* Consider any page scrolling: */
            x = x - window.pageXOffset;
            return x;
        }
        function slide(x: any) {
            /* Resize the image: */
            img.style.width = x + "px";
            /* Position the slider: */
            slider.style.left = img.offsetWidth - (slider.offsetWidth / 2) + "px";
        }
    }


    return (
        <div datatype={"category-content-image-comparison"} className={'mb-3 d-flex justify-content-center'}>
            <div className="img-comp-container" style={{aspectRatio: doc.aspectRatio}}>
                <div className="img-comp-img">
                    <img src={doc.image_1.imageUrl} alt={doc.image_1.imageAlt ?? ''} style={{width: '100%', aspectRatio: doc.aspectRatio}} />
                </div>
                <div className="img-comp-img img-comp-overlay">
                    <img src={doc.image_2.imageUrl} alt={doc.image_1.imageAlt ?? ''} style={{width: '100%', aspectRatio: doc.aspectRatio}} />
                </div>
            </div>
        </div>
    )
}
