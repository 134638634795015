export default function ContentSimpleList(doc: any) {

    function renderListItems(listItems: any) {
        return (
            <>
                {listItems.map((item: any) => (
                    <li key={item.id} style={{color: doc.backgroundColorText}}>{item.text}</li>
                ))}
            </>
        )
    }

    return (
        <div datatype={"category-content-simple-list"} className={"py-3 dark-theme-text"} style={{backgroundColor: doc.backgroundColor}}>
            {!doc.isBullet && <ol className={"mb-0"}> {renderListItems(doc.listItems)} </ol>}
            {doc.isBullet && <ul className={"mb-0"}> {renderListItems(doc.listItems)} </ul>}
        </div>
    )
}