import Image from "next/image";
import Link from "../link/Link";
import {imageSizes} from "../../utils/imageSizes";

export default function ContentImage(doc: any) {
    function image() {
        return (
            <Image datatype={"category-content-image"} src={doc.imageUrl} alt={doc.imageAlt ?? ''} width={1000}
                   sizes={imageSizes({
                       xs: 'calc(100vw - 56px)',
                       sm: '484px',
                       md: '664px',
                       lg: '904px',
                       xl: '1057px',
                       xxl: '1124px'
                   })}
                   priority={true}
                   quality={100}
                   height={1000} className={`w-100 h-auto ${doc.isShake && 'shaky'}`}/>
        )
    }

    if (doc.imageLink) {
        return (
            <Link href={doc.imageLink} datatype={"category-content-image"}>
                {image()}
            </Link>
        )
    }

    return image();
}
