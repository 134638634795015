import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import React from "react";
import Link from "../link/Link";

export default function ContentButtonSlider(doc: any) {

    return (
        <Swiper
            slidesPerView="auto"
            mousewheel={{forceToAxis: true}}
            freeMode
            modules={[Mousewheel, FreeMode]}
            wrapperClass="d-flex justify-content-between"
        >
            {doc.listItems.map((block: any, index: number) => {
                let className = 'w-auto';
                if (index > 0) {
                    className += ' ms-1';
                }
                if (index < doc.listItems.length - 1) {
                    className += ' me-1';
                }
                return (
                    <SwiperSlide className={className} key={index}>
                        <Link href={block.buttonLink}
                            role={"button"}
                            className={`btn ${!doc.isDarkTheme ? 'btn-black text-white' : 'btn-alt'} ${!block.nomargin && 'mt-2 mb-4 mt-3' } ps-3 pe-3 pt-2 pb-2 w-auto d-inline-blockme-4`}>
                            <span>{block.buttonText}</span>
                        </Link>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    )
}