import React, {useEffect, useState} from "react";
import i18next from "i18next";
import variantService from "../../services/product/variantService";
import cookieService from "../../services/cookie/cookieService";
import Link from "../link/Link";
import Image from "next/image";
import productLinkService from "../../services/product/productLinkService";
import imageService from "../../services/product/imageService";

export default function PastDailyDealTile(deal: any) {
    const [dailyDeal, setDailyDeal] = useState<any>();

    useEffect(() => {
        const fn = async () => {
            const data = [deal];
            await variantService.getVariants(data, false, cookieService.getCustomerGroup(), i18next.language);

            if (data && data.length > 0) {
                setDailyDeal(data[0]);
            }
        };

        fn().then();

    }, []);

    const breakpoints: any = {
        1: {
            slidesPerView: 1
        },
        340: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 3
        },
        992: {
            slidesPerView: 4
        },
        1200: {
            slidesPerView: 5
        },
    };

    function getCover(product: any, mainVariant: any) {
        if (product.cover && product.cover.includes('cdn.perfecthair.ch')) return product.cover;
        return mainVariant.imgDetailUrls ? imageService.getImage(mainVariant.imgDetailUrls, product.orderNumber) : imageService.getImage(mainVariant.imgMainUrls, product.orderNumber)
    }

    if (!dailyDeal) return <></>;

    return (
        <>
            <Link href={productLinkService.getLink(dailyDeal.product)} className="d-block dark">
                <div className={'overlay rounded-4 position-absolute w-100 h-100 top-0 start-0'}></div>
            </Link>

            <div className={`position-relative row`} >
                <div className={"col-md-12"}>
                    <Image src={getCover(dailyDeal.product, dailyDeal.product)}
                           alt={dailyDeal.product.name}
                           width={180}
                           height={180}
                           sizes="(max-width: 767px) 15vw, 10vw"
                           className="mb-3 mt-3"/>
                </div>
                <div className={"col-md-12"}>
                    <div className="product-tile-text product-tile-title mb-2"><b>{dailyDeal.product.brand}</b></div>
                    <div className="product-tile-text multi-line mb-2">{dailyDeal.product.name}</div>
                </div>
            </div>
        </>
    )
}