import React, {useEffect, useState} from "react";
import {locale} from "../../utils/locale";
import i18next from "i18next";
import ProductSlider from "../slider/Product";
import cookieService from "../../services/cookie/cookieService";
import apiInternal from "../../utils/apiInternal";

export default function ContentProductStream(doc: any) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fn = async () => {
            const res: any = await apiInternal().post('/api/v2/product/stream', {
                id: doc.streamId,
                languageId: locale(i18next.language),
                customerGroup: cookieService.getCustomerGroup()
            });

            let products = res.data;

            if (products.length > doc.maxCount) {
                products = products.slice(0, doc.maxCount);
            }

            setProducts(products);

        };

        fn().then();

    }, [])

    if (!products.length) return <></>

    return (
        <div datatype={'content-product-carousel'}>
            <ProductSlider products={products} md={1} lg={3} xl={3} perPage={doc.perPage} container={false}/>
        </div>
    )
}