import React, {useEffect, useState} from "react";
import {locale} from "../../utils/locale";
import i18next from "i18next";
import apiInternal from "../../utils/apiInternal";
import DailyDealTile from "../daily-deal/DailyDealTile";
import PastDailyDealTile from "../daily-deal/PastDailyDealTile";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import { useRouter } from 'next/router';
import moment from "moment-timezone";

export default function ContentDailyDeal(doc: any) {
    const [dailyDeal, setDailyDeal] = useState<any>();
    const [secondDailyDeal, setSecondDailyDeal] = useState<any>();
    const [dailyDeals, setDailyDeals] = useState([]);
    const [pastDailyDeals, setPastDailyDeals] = useState([]);

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const router = useRouter();

    useEffect(() => {
        const fn = async () => {
            const res: any = await apiInternal().post('/api/v2/product/deal', {
                languageId: locale(i18next.language),
                count: doc.numberOfPastDeals
            });
            const data = res.data;

            setDailyDeal(data.dailyDeals.shift());

            if (typeof doc.templateOption != 'undefined' && doc.templateOption == 1 ) {
                setSecondDailyDeal(data.dailyDeals.shift());
            }

            setDailyDeals(data.dailyDeals);
            setPastDailyDeals(data.pastDeals);

            if (data.nextDate !== null && data.nextDate !== false && data.nextDate !== "") {
                const ta = moment(data.nextDate).tz('Europe/Zurich');
                const interval = setInterval(() => {
                    const now = moment().tz('Europe/Zurich').add(1, 'hours');
                    // const difference = target.getTime() - now.getTime() - (1000 * 60 * 60);
                    const difference = ta.diff(now, 'seconds');

                    // const d = Math.floor(difference / (1000 * 60 * 60 * 24));
                    const d = Math.floor(difference / (60 * 60 * 24));
                    // const d = ta.diff(now, 'days');
                    setDays(d);

                    const h = Math.floor((difference % (60 * 60 * 24)) / (60 * 60));
                    // const h = ta.diff(now, 'hours');
                    setHours(h);

                    const m = Math.floor((difference % (60 * 60)) / (60));
                    // const m = ta.diff(now, 'minutes');
                    setMinutes(m);

                    const s = Math.floor((difference % (60)));
                    // const s = ta.diff(now, 'seconds');
                    setSeconds(s);

                    if (difference <= 0 && difference > -10) {
                        router.reload();
                    }
                }, 1000);

                return () => clearInterval(interval);
            }
        };

        fn().then();

    }, []);

    const breakpoints: any = {
        1: {
            slidesPerView: 1
        },
        340: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 3
        },
        992: {
            slidesPerView: 4
        },
        1200: {
            slidesPerView: 4
        },
    };

    function showCountdown() {
        if (!doc.showCountdown) return <></>;

        return (
            <div className={"daily-deal-cd-h"}>
                <div className={"dark-theme-text"} dangerouslySetInnerHTML={{__html: doc.countdownText ? doc.countdownText : ''}}/>

                <div className={"rounded-3 p-5 pt-0 mt-3 mb-4 text-center"} datatype={"content-count-down"}>
                    <div className={"d-flex flex-row m-auto justify-content-center countdown"}>
                        <div className={"me-1 me-md-3"}>
                            <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>{i18next.t('countdown.days').toString()}</div>
                            <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{days}</div>
                        </div>

                        <div className={"me-1 me-md-3"}>
                            <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>{i18next.t('countdown.hours').toString()}</div>
                            <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{hours}</div>
                        </div>

                        <div className={"me-1 me-md-3"}>
                            <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>{i18next.t('countdown.minutes').toString()}</div>
                            <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{minutes}</div>
                        </div>

                        <div className={"me-1 me-md-3"}>
                            <div className={"bg-white m-auto rounded-5 pt-2 pb-2 fw-bold"}>{i18next.t('countdown.seconds').toString()}</div>
                            <div className={"bg-white p-3 rounded-4 fw-bold fs-3 box text-center mt-3"}>{seconds}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function showPastDeals() {
        if (!doc.showPastDeals) return <></>;

        return (
            <>
                <h2 className={`mt-5 mb-3 text-center dark-theme-text`} datatype={"category-content-h2"}>{doc.titlePastDeals}</h2>

                <Swiper pagination={{clickable: true}}
                    breakpoints={breakpoints}
                    mousewheel={{forceToAxis: true}}
                    freeMode={{sticky: true}}
                    modules={[Pagination, Mousewheel, FreeMode]}
                    className="product-slider pb-4 overflow-hidden d-block px-3"
                    preventClicks={true}
                    spaceBetween={15}
                    loop={false}>
                        {pastDailyDeals.map((deal: any, index: number) => (
                                <SwiperSlide key={index} className={"slide border border-3 d-block position-relative rounded-4 p-4 bg-white"}>
                                    <PastDailyDealTile
                                        product={deal}
                                        className={"w-100 ms-auto me-auto p-3"}
                                        showDescription={true}
                                        style={{maxWidth: '600px'}} />
                                </SwiperSlide>
                            ))}
                </Swiper>
            </>
        )

    }

    function templateDefault() {
        return (
            <>
                <DailyDealTile
                    product={dailyDeal}
                    productTile={false}
                    className={"w-100 ms-auto row me-auto rounded-4 p-3"}
                    showDescription={true}
                    style={{maxWidth: '800px'}}
                    showWide={true}
                    showCart={true}
                />

                {showCountdown()}

                {dailyDeals.length > 0 ? (
                    <>
                        <div className={"dark-theme-text"} dangerouslySetInnerHTML={{__html: doc.activeDealsText ? doc.activeDealsText : ''}}/>

                        <div className={'position-relative w-100 grid col-1 col-md-2'}>
                            {dailyDeals.map((deal: any, index: number) => (
                                <DailyDealTile
                                    key={deal.id}
                                    product={deal}
                                    productTile={false}
                                    className={"m-md-4 mb-3 p-3 rounded-4"}
                                    showDescription={false}
                                    style={{maxWidth: '600px'}}
                                    showCart={true}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <></>
                ) }
            </>
        );
    }

    function templateTwoColumn() {
        if (!secondDailyDeal) {
            return (<></>);
        }

        return (
            <>
                <div className={'position-relative w-100 grid col-1 col-md-2'}>
                    <DailyDealTile
                        product={dailyDeal}
                        productTile={false}
                        className={"m-md-4 mb-3 p-3 rounded-4"}
                        showDescription={false}
                        style={{maxWidth: '600px'}}
                        showCart={true}
                    />
                    <DailyDealTile
                        product={secondDailyDeal}
                        productTile={false}
                        className={"m-md-4 mb-3 p-3 rounded-4"}
                        showDescription={false}
                        style={{maxWidth: '600px'}}
                        showCart={true}
                    />
                </div>

                {showCountdown()}

                <div className={"dark-theme-text"} dangerouslySetInnerHTML={{__html: doc.activeDealsText ? doc.activeDealsText : ''}}/>

                {dailyDeals.length > 0 ? (
                    <>
                        <div className={'position-relative w-100 grid col-1 col-md-2'}>
                            {dailyDeals.map((deal: any, index: number) => (
                                    <DailyDealTile
                                        key={deal.id}
                                        product={deal}
                                        productTile={false}
                                        className={"m-md-4 mb-3 p-3 rounded-4"}
                                        showDescription={false}
                                        style={{maxWidth: '600px'}}
                                        showCart={true}
                                    />
                                ))}
                        </div>
                    </>
                ) : (
                    <></>
                ) }

            </>
        );
    }

    function templateOneColumn() {
        return (
            <>
                <DailyDealTile
                    product={dailyDeal}
                    productTile={false}
                    className={"w-100 ms-auto row me-auto rounded-4 p-3"}
                    showDescription={true}
                    style={{maxWidth: '800px'}}
                    showWide={true}
                    showCart={true}
                />

                {showCountdown()}

                <div className={"dark-theme-text"} dangerouslySetInnerHTML={{__html: doc.activeDealsText ? doc.activeDealsText : ''}}/>

                {dailyDeals.length > 0 ? (
                    <>
                        <div className={'position-relative w-100'}>
                            {dailyDeals.map((deal: any, index: number) => (
                                <DailyDealTile
                                    key={deal.id}
                                    product={deal}
                                    productTile={false}
                                    className={"w-100 ms-auto row me-auto rounded-4 p-3"}
                                    showDescription={true}
                                    style={{maxWidth: '800px'}}
                                    showWide={true}
                                    showCart={true}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <></>
                ) }
            </>
        );
    }

    if (!dailyDeal) return <></>;

    return (
        <div className={'content-product-daily-deal'} datatype={'content-product-daily-deal'}  data-per-page={3}>

            <h2 className={`mt-5 mb-3 text-center dark-theme-text`} datatype={"category-content-h2"}>{dailyDeal.title}</h2>


            {typeof doc.templateOption == 'undefined' || doc.templateOption == 0 ? (
                templateDefault()
            ) : (
                <></>
            )}

            {typeof doc.templateOption != 'undefined' && doc.templateOption == 1 ? (
                templateTwoColumn()
            ) : (
                <></>
            )}

            {typeof doc.templateOption != 'undefined' && doc.templateOption == 2 ? (
                templateOneColumn()
            ) : (
                <></>
            )}


            {showPastDeals()}

        </div>
    )
}