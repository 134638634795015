import React, {useEffect, useState} from "react";
import apiInternal from "../../utils/apiInternal";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import Link from "../link/Link";
import Image from "next/image";
import i18next from "i18next";

export default function ContentBrandSlider(doc: any) {
    const [brands, setBrands] = useState([]);

    const breakpoints = {
        0: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 3
        },
        1200: {
            slidesPerView: 5
        },
    };

    useEffect(() => {
        const fn = async () => {

            if (doc.filterType == 'manuel') {
                const resBrands = await apiInternal().post('/api/v2/brand/slider', {type: doc.filterType, ids: doc.listItems});
                setBrands(resBrands.data);
            } else if (doc.filterType == 'category') {
                const resBrands = await apiInternal().post('/api/v2/brand/slider', {type: doc.filterType, id: doc.categoryId, limit: doc.maxCount});
                setBrands(resBrands.data);
            } else {
                const resBrands = await apiInternal().post('/api/v2/brand/slider', {type: doc.filterType, limit: doc.maxCount});
                setBrands(resBrands.data);
            }
        };

        fn().then();
    }, [])



    if (!brands.length) return <></>

    if (doc.displayAs === 'grid') {
        return(
            <div datatype={'content-brand-slider'} className={"content-brand-slider d-flex justify-content-between flex-wrap"}>
                {brands.map((brand: any) => (
                    <div className={"grid-item"} key={brand.id}>
                        <Link
                            href={`/${i18next.language}/${brand.link}`}
                            passHref className="d-block">
                            <Image src={brand.image}
                                   alt={brand.name}
                                   height={180}
                                   width={400}
                                   sizes="(min-width: 768px) 150px, 150px"
                                   className={"w-100 h-auto"}/>
                        </Link>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div datatype={'content-brand-slider'} className={"content-brand-slider"}>
            <Swiper slidesPerView={5}
                    breakpoints={breakpoints}
                    pagination={{clickable: true}}
                    mousewheel={{forceToAxis: true}}
                    freeMode={{sticky: true}}
                    navigation={true}
                    modules={[Mousewheel, FreeMode, Navigation]}
                    className="brand-slider pb-4">
                {brands.map((brand: any) => (
                    <SwiperSlide key={brand.id}>
                        <Link
                            href={`/${i18next.language}/${brand.link}`}
                            passHref className="d-block">
                            <Image src={brand.image} alt={brand.name} height={180} width={400}
                                   className={"w-100 h-auto"}/>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}