import { useState } from "react";
import Link from "../link/Link";
export default function  SimpleAccordion({title, text, buttonText, buttonLink}: any) {
    const [open, setOpen] = useState(false);

    const contentClassname = open ? 'd-block' : 'd-none';

    return (
        <div className="w-100 border-top border-black dark-theme-text">
            <p className={"pt-1"} role="button" onClick={() => setOpen(!open)}>
                <strong>{title}</strong>
            </p>
            <div className={`pb-3 ${contentClassname}`}>
                <div dangerouslySetInnerHTML={{__html: text ? text : ''}}></div>

                {(buttonText) && (
                    <div className={`d-flex justify-content-end`} datatype={"content-call-to-action"}>
                        <Link href={buttonLink}
                              role={"button"}
                              className={`btn btn-black text-white fw-bold ps-3 pe-3 pt-2 pb-2 w-auto d-inline-blockme-4`}>
                            <span>{buttonText}</span>
                            <i className="fa-solid fa-chevron-right ms-2 mt-1 text-white"></i>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};
