import ContentSimpleAccordion from "./SimpleAccordion";

export default function ContentAccordion(doc: any) {

    return (
        <div datatype={"category-content-simple-list"} className={"py-3"} style={{backgroundColor: doc.backgroundColor}}>
            {doc.listItems.map((item: any) => (
                <div className={"content-accordion"} key={item.id}>
                    <ContentSimpleAccordion key={item.id} title={item.headline} text={item.text} buttonText={item.buttonText} buttonLink={item.link}></ContentSimpleAccordion>
                </div>
            ))}
        </div>
    )
}
