export default function ContentYoutubeVideo(doc: any) {

    let videoWidth: string = '100%';
    if (doc.videoWidth) {
        videoWidth = doc.videoWidth;
    }
    let aspectRatio: string = '16/9';
    if (doc.aspectRatio) {
        aspectRatio = doc.aspectRatio;
    }

    try {
        return (
            <div className={'d-flex justify-content-center'}>
                <div className={'content-youtube-video'} style={{width: videoWidth, aspectRatio: aspectRatio}} dangerouslySetInnerHTML={{__html: doc.youtubeVideoCode}}/>
            </div>
        )
    }
    catch(e) {
        return <></>
    }
}