import Link from "../link/Link";

export default function ContentCallToAction(doc: any) {
    if (!doc) return <></>

    let alignment: string = '';

    switch (doc.alignment) {
        case 'center':
            alignment = 'justify-content-center';
            break;
        case 'right':
            alignment = 'justify-content-end'
            break;
        default:
            alignment = 'justify-content-start'
    }

    return (
        <div className={`d-flex ${alignment}`} datatype={"content-call-to-action"}>
            <Link href={doc.buttonLink}
                  role={"button"}
                  className={`btn btn-black text-white fw-bold ${!doc.nomargin && 'mt-2 mb-4 mt-3' } ps-3 pe-3 pt-2 pb-2 w-auto d-inline-blockme-4`}>
                <span>{doc.buttonText}</span>
                <i className="fa-solid fa-chevron-right ms-2 mt-1 text-white"></i>
            </Link>
        </div>
    )
}