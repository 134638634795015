export default function ContentIframe(doc: any) {
    let h: string = '';
    let aR: string = '';

    if (doc.height) {
        h = doc.height+'px';
    } else {
        aR = doc.ratio;
    }

    return (
        <div datatype={"category-content-iframe"} className={'mb-3 d-flex justify-content-center'}>
             <iframe src={doc.url} width={doc.width} style={{height: h, aspectRatio: aR}}/>
        </div>
    )
}