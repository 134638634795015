import Image from "next/image";
import ContentCallToAction from "./CallToAction";
import Link from "../link/Link";
import {imageSizes} from "../../utils/imageSizes";

export default function ContentImageTextAction(doc: any) {

    let spaceNeeded: string = '2';
    let mediaDisplay: string = 'd-flex';

    if (doc.isVideo) {
        if (!doc.video.videoUrl) {
            spaceNeeded = '1';
            mediaDisplay =  'd-none';
        }
    } else {
        if (!doc.image.imageUrl) {
            spaceNeeded = '1';
            mediaDisplay =  'd-none';
        }
    }


    let text: string = 'order-'+spaceNeeded+' order-md-1';
    let media: string = 'order-1 order-md-'+spaceNeeded+'';
    let col: string = 'col-md-'+spaceNeeded+'';
    let row: string = 'row-md-1';
    let alignmentV: string = '';
    let alignmentH: string = '';

    switch (doc.alignmentsText) {
        case 'right':
            text = 'order-'+spaceNeeded+'';
            media = 'order-1';
            break;

        case 'top':
            col = 'col-md-1';
            break;

        case 'bottom':
            col = 'col-md-1';
            text = 'order-'+spaceNeeded+'';
            media = 'order-1';
            break;
    }

    switch (doc.alignmentsV) {
        case 'top':
            alignmentV = 'd-flex align-items-start';
            break;

        case 'middle':
            alignmentV = 'd-flex align-items-center';
            break;

        case 'bottom':
            alignmentV = 'd-flex align-items-end';
            break;
    }

    switch (doc.alignmentsH) {
        case 'left':
            alignmentH = 'd-flex justify-content-start';
            break;

        case 'center':
            alignmentH = 'd-flex justify-content-center';
            break;

        case 'right':
            alignmentH = 'd-flex justify-content-end';
            break;
    }

    function getText() {
        return (
            <>
                <div className={"d-flex flex-column dark-theme-text"}>
                    <p dangerouslySetInnerHTML={{__html: doc.text ? doc.text : ''}}></p>

                    {(doc.callToAction && doc.callToAction.buttonText) && (
                        <ContentCallToAction {...{...doc.callToAction, nomargin:true}} />
                    )}
                </div>
            </>
        )
    }

    function getMedia() {
        if (doc.isVideo) {
            return getVideo();
        } else {
            return getImage();
        }
    }

    function getVideo() {
        try {
            if (!doc.video.videoUrl) return <></>
            if (!doc.video.isYoutubeVideo) return (
                <video controls
                       preload={""}
                       poster={""}
                       autoPlay
                       loop
                       muted
                       style={{maxHeight: "400px", aspectRatio: "16/9"}}
                       datatype={"content-video"}
                       className={"w-100 h-auto mb-4"}>
                    <source key={doc.video.id} src={doc.video.videoUrl} type={"video/mp4"}/>
                </video>
            )
            if (!doc.video.youtubeCode) return <></>
            return (
                <div className={'content-youtube-video w-100'} style={{aspectRatio: "16/9"}} dangerouslySetInnerHTML={{__html: doc.video.youtubeCode}}/>
            )
        } catch (e) {
            console.error('video text action error', e);
            return <></>
        }
    }

    function getImage() {
        try {
            if (!doc.image.imageUrl) return <></>

            if (doc.image.imageLink) {
                return (
                    <Link href={doc.image.imageLink} datatype={"category-content-image-link"}>
                        <Image src={doc.image.imageUrl} alt={doc.image.imageAlt ?? ''} width={1000} height={1000}
                               sizes={imageSizes({
                                   xs: 'calc(100vw - 24px)',
                                   sm: '516px',
                                   md: `calc((336px * 2) / ${col})`,
                                   xl: `calc((394px * 2) / ${col})`,
                               })}
                               priority={true}
                               className={`w-100 h-auto`}/>
                    </Link>
                )
            } else {
                return (
                    <Image src={doc.image.imageUrl} alt={doc.image.imageAlt ?? ''} width={1000} height={1000}
                           sizes={imageSizes({
                               xs: 'calc(100vw - 24px)',
                               sm: '516px',
                               md: `calc((336px * 2) / ${col})`,
                               xl: `calc((394px * 2) / ${col})`,
                           })}
                           priority={true}
                           className={`w-100 h-auto`}/>
                )
            }
        } catch (e) {
            console.error('image text action error', e);
            return <></>
        }
    }

    return (
        <div className={`grid ${row} ${col} mt-4 mb-4 w-100`} datatype={"content-image-text-action"}>
            <div className={`${text} ${alignmentV} ${alignmentH} `} style={{backgroundColor: doc.backgroundColor ?? ''}}>
                {getText()}
            </div>
            <div className={`${media} ${mediaDisplay}`}>
                {getMedia()}
            </div>
        </div>
    )
}
