import React from "react";
import ContentCallToAction from "../../components/content/CallToAction";
import ContentColumns from "../../components/content/Columns";
import ContentCountDown from "../../components/content/Countdown";
import ContentH1 from "../../components/content/H1";
import ContentH2 from "../../components/content/H2";
import ContentH3 from "../../components/content/H3";
import ContentH4 from "../../components/content/H4";
import ContentH5 from "../../components/content/H5";
import ContentHeadline from "../../components/content/Headline";
import ContentHr from "../../components/content/Hr";
import ContentImage from "../../components/content/Image";
import ContentImageCarousel from "../../components/content/ImageCarousel";
import ContentImageColumn from "../../components/content/ImageColumn";
import ContentImageGrid from "../../components/content/ImageGrid";
import ContentImageText from "../../components/content/ImageText";
import ContentPortfolioFilter from "../../components/content/PortfolioFilter";
import ContentBrandSlider from "../../components/content/BrandSlider";
import ContentProductCarousel from "../../components/content/ProductCarousel";
import ContentProductStream from "../../components/content/ProductStream";
import ContentIframe from "../../components/content/Iframe";
import ContentImageComparison from "../../components/content/ImageComparison";
import ContentSimpleList from "../../components/content/SimpleList";
import ContentAccordion from "../../components/content/Accordion";
import ContentText from "../../components/content/Text";
import ContentSpecialEffects from "../../components/content/SpecialEffects";
import ContentVideo from "../../components/content/Video";
import ContentYoutubeVideo from "../../components/content/YoutubeVideo";
import ContentSocialMedia from "../../components/content/SocialMedia";
import ContentdailyDeal from "../../components/content/DailyDeal";
import ContentImageTextAction from "../../components/content/ImageTextAction";
import ContentHtml from "../../components/content/Html";
import ContentEventCalendar from "../../components/content/EventCalendar";
import ContentBubbleSlider from "../../components/content/BubbleSlider";
import ContentButtonSlider from "../../components/content/ButtonSlider";
import ContentAnchor from "../../components/content/Anchor";

class ContentRenderer {
  components: any = {
    callToAction: ContentCallToAction,
    columns: ContentColumns,
    countdown: ContentCountDown,
    headline: ContentHeadline,
    h1: ContentH1,
    h2: ContentH2,
    h3: ContentH3,
    h4: ContentH4,
    h5: ContentH5,
    hr: ContentHr,
    image: ContentImage,
    imageCarousel: ContentImageCarousel,
    imageColumn: ContentImageColumn,
    imageGrid: ContentImageGrid,
    imageText: ContentImageText,
    imageTextAction: ContentImageTextAction,
    portfolioFilter: ContentPortfolioFilter,
    brandSlider: ContentBrandSlider,
    productCarousel: ContentProductCarousel,
    productStream: ContentProductStream,
    iframe: ContentIframe,
    imageComparison: ContentImageComparison,
    accordion: ContentAccordion,
    simpleList: ContentSimpleList,
    text: ContentText,
    video: ContentVideo,
    youtubeVideo: ContentYoutubeVideo,
    socialMedia: ContentSocialMedia,
    dailyDeal: ContentdailyDeal,
    specialEffect: ContentSpecialEffects,
    pasteAnything: ContentHtml,
    eventCalendar: ContentEventCalendar,
    bubbleSlider: ContentBubbleSlider,
    buttonSlider: ContentButtonSlider,
    anchor: ContentAnchor,
  }

  renderComponent(type: string, doc: any) {
    const DynamicComponent = this.components[type];
    if (!DynamicComponent) return React.createElement('div');
    return React.createElement(DynamicComponent, {...doc});
  }
}

const contentRenderer = new ContentRenderer();
export default contentRenderer