import contentRenderer from "../../services/content/contentRenderer";

export default function ContentColumns(doc: any) {
    const columns = doc.columns.filter((col: any) => col.isVisible);
    let cols: number = columns.length;
    const x = 12 / cols;
    const even = (x - (Math.floor(x))) === 0;

    function renderGrid(columns: any) {
        return (
            <div className={`grid row-1 col-md-${cols} w-100 overflow-hidden`} datatype={"content-columns"}>
                {columns.map((col: any) => (
                    <div key={col.id} className={`mb-4 mb-md-0`} data-id={col.id} data-cols={cols}>
                        {renderCol(col)}
                    </div>
                ))}
            </div>
        )
    }

    function renderRow(columns: any) {
        return (
            <div className={`row overflow-hidden`} datatype={"content-columns"}>
                {columns.map((col: any) => (
                    <div key={col.id} className={`d-block col-12 col-md-${x} mb-4 mb-md-0`} data-id={col.id} data-cols={cols}>
                        {renderCol(col)}
                    </div>
                ))}
            </div>
        )
    }

    function renderCol(col: any) {
        return (
            <div key={col.id} className={`d-block mb-4 mb-md-0`} data-id={col.id} data-cols={cols}>
                {col.columnBlockList.map((item: any) => (
                    <div key={item.id} className={"d-contents"}>
                        {contentRenderer.renderComponent(item.type, item)}
                    </div>
                ))}
            </div>
        )
    }

    if (even) return renderRow(columns)
    return renderGrid(columns)
}