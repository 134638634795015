import Image from "next/image";
import ContentCallToAction from "./CallToAction";
import {imageSizes} from "../../utils/imageSizes";

export default function ContentImageText(doc: any) {
    let text: string = 'order-1';
    let image: string = 'order-2';
    let col: string = 'col-md-6';

    switch (doc.alignmentsText) {
        case 'right':
            text = 'order-2';
            image = 'order-1';
            break;

        case 'top':
            col = 'col-md-12';
            break;

        case 'bottom':
            col = 'col-md-12';
            text = 'order-2';
            image = 'order-1';
            break;
    }

    function getText() {
        return (
            <>
                <p className={`fw-bold mt-4 dark-theme-text ${doc.isTextOverImage && 'overlay-text'}`}>{doc.titleText}</p>
                <div className={`dark-theme-text ${doc.isTextOverImage && 'overlay-text'}`}
                     dangerouslySetInnerHTML={{__html: doc.text ? doc.text : ''}}/>

                {doc.callToAction && (
                    <ContentCallToAction {...{...doc.callToAction, nomargin:true}} />
                )}
            </>
        )
    }

    function getImage() {
        return (
            <Image src={doc.image.imageUrl} alt={doc.image.imageAlt ?? ''} width={1000} height={100}
                   className={`w-100 h-auto`}/>
        )
    }

    if (doc.isTextOverImage) {
        return (
            <div className={'position-relative'}>
                {getImage()}
                <div className={'position-absolute translate-middle-y top-50 end-0 w-50 me-4'}>
                    <div className={"bg-dark-alpha rounded-3 p-3 text-white"}>
                        {getText()}
                    </div>
                </div>
            </div>
        )
    }

    if (!doc.text) return getImage();

    return (
        <div className={"row mt-3 mb-4"} datatype={"content-image-text"}>
            <div className={`col-12 ${col} ${text}`}>
                {getText()}
            </div>
            <div className={`col-12 ${col} ${image}`}>
                {getImage()}
            </div>
        </div>
    )
}