export default function ContentSpecialEffects(doc: any) {

    if (doc.specialEffect == 'snow') {
        const arr = new Array(32).fill(1);
        return (
            <div>
                { arr.map((i: any) => (<div className="snow"></div>))}
            </div>
        )
    }

    function getRandomArbitrary(min: any, max: any) {
        return Math.random() * (max - min) + min;
    }

    function generateHearCSS(i: any) {
        const random_x = getRandomArbitrary(1, 1000000) * 0.0001;
        const random_offset = getRandomArbitrary(-100000, 100000) * 0.0001;
        const random_x_end= random_x - random_offset;
        const random_x_end_yoyo = random_x - (random_offset / 2);
        const random_yoyo_time = getRandomArbitrary(100, 800) / 1000;
        const random_yoyo_y = random_yoyo_time * 100;
        const random_scale = getRandomArbitrary(1, 10000) * 0.0001;
        const fall_duration = getRandomArbitrary(10, 30) * 1;
        const fall_delay = getRandomArbitrary(1, 30) * -1;

        const opacity = getRandomArbitrary(30, 100) * 0.01;


        return (
            <>
            <div key={'heart-'+i} className={`heart h-${i}`}></div>
            <style key={'heart-jsx-'+i} jsx>
                {
                    `
                    .heart {
                        animation: heart-fall-${i} ${fall_duration}s ${fall_delay}s linear ${doc.specialEffectDuration};
                    }
                    @keyframes heart-fall-${i} {
                        0% {
                            opacity: 0;
                            transform: translate(${random_x_end}vw, ${random_yoyo_y}vh) scale(${random_scale});
                        }
                        15% {
                            opacity: ${opacity};
                        }
                        100% {
                            transform: translate(${random_x_end_yoyo}vw, 0vh) scale(${random_scale});
                        }
                    }
                    `
                }
            </style>
            </>
        );

    }


    if (doc.specialEffect == 'hearts') {
        // const arr = new Array(33).fill(1);
        const arr = Array.from(Array(33).keys());
        return (
            <>
                { arr.map((i: any) => (generateHearCSS(i)))}
            </>
        )
    }


}