export default function ContentSocialMedia(doc: any) {

    let width: string = '100%';
    if (doc.width) {
        width = doc.width;
    }
    let height: string = '';
    if (doc.height) {
        height = doc.height+'px';
    }
    let aspectRatio: string = '16/9';
    if (doc.aspectRatio) {
        aspectRatio = doc.aspectRatio;
    }

    if (doc.youtubeVideoCode) {
        try {
            return (
                <div className={'d-flex justify-content-center'}>
                    <div className={'content-youtube-video'} style={{width: width, aspectRatio: aspectRatio}} dangerouslySetInnerHTML={{__html: doc.youtubeVideoCode}}/>
                </div>
            )
        }
        catch(e) {
            return <></>
        }
    }

    let iFrame = <></>;

    if(doc.socialMediaType == 'youtube') {
        iFrame = <iframe src={doc.codeSnippet} width={doc.width} style={{height: height, aspectRatio: aspectRatio}}
                    title="YouTube video player"
                    allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture; web-share" />;
    }
    if(doc.socialMediaType == 'youtubeShorts') {
        iFrame = <iframe src={'https://www.youtube.com/embed/'+doc.codeSnippet} width={doc.width} style={{height: height, aspectRatio: aspectRatio}}
                    allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture; web-share" />;
    }
    if(doc.socialMediaType == 'instagram') {
        iFrame = <iframe src={'https://www.instagram.com/p/'+doc.codeSnippet+'/embed/'} width={doc.width} 
                    style={{height: height, aspectRatio: aspectRatio, background: 'white', maxWidth: 'calc(100% - 2px)', 
                        minWidth: '326px', borderRadius: '3px', border: '1px solid rgb(219, 219, 219)', boxShadow: 'none', 
                        display: 'block', margin: 'auto', padding: '0', overflow: 'hidden'}} 
                    className="content-iframe-social-media instagram-media instagram-media-rendered"
                    data-instgrm-payload-id="instagram-media-payload-" 
                    allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture; web-share" />;
    }
    if(doc.socialMediaType == 'instagramReel') {
        iFrame = <iframe src={'https://www.instagram.com/reel/'+doc.codeSnippet+'/embed/'} width={doc.width} 
                    style={{height: height, aspectRatio: aspectRatio, background: 'white', maxWidth: 'calc(100% - 2px)', 
                        minWidth: '326px', borderRadius: '3px', border: '1px solid rgb(219, 219, 219)', boxShadow: 'none', 
                        display: 'block', margin: 'auto', padding: '0', overflow: 'hidden'}} 
                    className="instagram-media instagram-media-rendered"
                    data-instgrm-payload-id="instagram-media-payload-" 
                    allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture; web-share" />;
    }
    if(doc.socialMediaType == 'printerest') {
        iFrame = <iframe src={'https://assets.pinterest.com/ext/embed.html?id='+doc.codeSnippet} width={doc.width}
                    className="content-iframe-social-media"
                    style={{height: height, aspectRatio: aspectRatio}}
                    allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture; web-share" />;
    }
    if(doc.socialMediaType == 'tiktok') {
        iFrame = <iframe src={'https://www.tiktok.com/embed/v2/'+doc.codeSnippet} width={doc.width}
                    className="content-iframe-social-media"
                    style={{height: height, aspectRatio: aspectRatio, display: 'block', visibility: 'unset', margin: 'auto'}}
                    allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin" />;
    }

    try {
        return (
            <div className={'d-flex justify-content-center'}>

                {iFrame}

            </div>
        )
    }
    catch(e) {
        return <></>
    }
}